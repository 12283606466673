<williams-ui-platform-stack-layout [gap]="'0px'" *ngIf="showHomePage">
    <williams-ui-platform-stack-layout [gap]="'0px'" class="w-flex-basis-6 w-bg-primary-pt9  flex-grow-0">
        <williams-ui-platform-drawer [width]="260" themeColor="primary" [items]="menus" (select)="onSelect($event)">
            <williams-ui-platform-drawer-templates>
                <ng-template #headerTemplate>
                  <img src="assets/w-ui-angular/images/discovery_logo.png" alt="" class="discoverylogo">
                </ng-template>
            </williams-ui-platform-drawer-templates>
        </williams-ui-platform-drawer>
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout class="mainContainer" orientation="vertical" [gap]="'0px'">
        <williams-ui-platform-stack-layout orientation="vertical">
            <williams-ui-platform-header></williams-ui-platform-header>
        </williams-ui-platform-stack-layout>
        <router-outlet></router-outlet>
    </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>
<williams-ui-platform-stack-layout *ngIf="showHomePage" [gap]="'0px'">
    <williams-ui-platform-stack-layout class="w-bg-primary-pt9 flex-grow-0" style="flex-basis: 260px;">
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout>
        <williams-ui-platform-footer></williams-ui-platform-footer>
    </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>
<williams-ui-platform-pdf-viewer *ngIf="!showHomePage"></williams-ui-platform-pdf-viewer>