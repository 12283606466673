import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SiteMap } from '../../store/model/site-map.interface';
import { SiteMapService } from '../../store/services/site-map.service';

@Component({
  selector: 'williams-ui-platform-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent implements OnInit {
  siteMapdata!: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private siteMapService: SiteMapService,private router: Router) { }

  ngOnInit(): void {
    this.getSiteMapData();
  }

  getSiteMapData() {
    this.siteMapService.getSiteMapData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: SiteMap) => { this.siteMapdata = res; })
  }

  navigateUrl(val:any){
      this.router.navigateByUrl(`/${val}`);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
