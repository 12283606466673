export const CRITICAL_NOTICES = "Critical Notices";
export const CRITICAL_NOTICE = "Critical Notice";
export const POSTED_DATE_TIME = "Posted Date/Time";
export const EFFECTIVE_DATE = "Effective Date/Time";
export const INDEX_OF_CUSTOMERS_TITLE = "Index of Customers";
export const VIEW_DOWNLOAD_IOC_TITLE = "You can view or download the Index of Customers in different formats.";
export const VIEW = "View";
export const DOWNLOAD = "Download";
export const FILE_TYPE = "File Type";
export const PLANNED_OUTAGE_SERVICE = "Planned Service Outages Notices";
export const REQUEST_TO_PURCHASE = "Request To Purchase Releasable Capacity Notices";
export const LOCATIONS = "Locations";
export const VIEW_DOWNLOAD_LOCATIONS_TITLE = "You can view or download the Locations in different formats."
export const NON_CRITICAL_NOTICES = "Non-Critical Notices";
export const NON_CRITICAL_NOTICE = "Non-Critical Notice";
export const NO_REQUEST_TO_PURCHASE_RELEASABLE = "No Request to Purchase Releasable Capacity Notices Found";
export const NO_CRITICAL_NOTICES = "No Critical Notices Found";
export const NO_NON_CRITICAL_NOTICES = "No Non-Critical Notices Found";
export const NO_PLANNED_SERVICE_OUTAGE = "No Planned Service Outages Notices Found";
export const DISCOVERY_CONTACT_LIST = "Discovery Contact List";
export const DISCOVERY_GAS_TRANSMISSION = "Discovery Gas Transmission LLC";
export const PLANNED_SERVICE_OUTAGE_NOTICE = "Planned Service Outages Notice";
export const REQUEST_TO_PURCHASE_RELEASABLE = "Request To Purchase Releasable Capacity Notice";
export const EFFECTIVE_DATE_TIME = "Effective Date";
export const SCHEDULING_CYCLE = "Scheduling Cycle";
export const END_DATE_TIME = "End Date";
export const POSTING_DATE_TIME = "Posting Date";
export const EXPORT_TO_CSV = "Export to CSV";
export const CAPACITY_REPORT_CSV_NAME= "capacity-report.csv";
export const OPERATIONALLY_AVAILABLE_CSV_NAME= "operationally-available.csv";
export const FLOWING_GAS_CSV_NAME= "flowing-gas.csv";
export const CRITICAL_NOTICE_CSV_NAME = "critical-notices.csv";
export const NON_CRITICAL_NOTICE_CSV_NAME = "non-critical-notices.csv";
export const PLANNED_SERVICE_OUTAGE_CSV_NAME = "planned-service-outages.csv";
export const REQUEST_TO_PURCHASE_CSV_NAME = "requet-to-purchase.csv";
export const DISCOVERY_GAS_ADDRESS = "2800 Post Oak Boulevard, Level 3, Houston, Texas 77056"
export const DISCOVERY_GAS_ADDRESS1 ="P.O. Box 1396, Houston, Texas 77251-1396";
export const DOWNLOAD_CSV = "Export to CSV";
export const SHOW_ALL = "Show all";
export const THERE_ARE_NO_CRITICAL_NOTICES = "There are no critical notices at this time.";
export const THERE_ARE_NO_PLANNED_SERVICE = "There are no planned service outages at this time.";
export const CONTACTS = "Contact Us";
export const VIEW_ALL_CONTACTS = " View All Contacts";
export const PLANNED_SERVICE_OUTAGE = "Planned Service Outage";
export const ADDRESS_1 = "Discovery Gas Transmission LLC";
export const ADDRESS_2 = "2800 Post Oak Boulevard, Level 3,";
export const ADDRESS_3 = "Houston, Texas 77056";
export const ADDRESS_4 = "P.O.Box 1396, Houston, Texas 77251-1396";
export const PDF_VIEWER = "pdf-viewer";
export const DEFAULT_DATE_FORMAT= 'MM-dd-yyyy';
export const DEFAULT_DATE_TIME_FORMAT= 'MM-dd-yyyy hh:mm:ss a';
export const TIME_FORMAT_24 = "MM-dd-yyyy HH:mm:ss"
export const DEFAULT_LOCALE= 'en-us';
export const DIGIT_FORMAT= '1.0-0';
export const PAGE_NOT_AVAILABLE = "The page you are looking for is not available.";
export const PAGE_ERROR = "Page Error";
// Request param constant
export const noticeType = new Map<string, string>([
  ["critical", "C"],
  ["plannedServiceOutage", "P"],
  ["requestToPurchase", "R"],
  ["non-critical", "N"]
]);

export const TariffPDFMap = new Map<string,number>([
  ['title',1],
  ['toc',1],
  ['tsindex',161],
  ['prelim',6],
  ['fosa',116],
  ['ratesched',21],
  ['rates',9],
  ['gtc',35],
  ['specs',85]
])